<template>
  <v-footer
    id="dashboard-core-footer"
    absolute
  >
    <v-container
      id="footer-container"
      fluid
    >
      <v-row
        align="center"
        no-gutters
      >
        <a
          href="http://www.dbsystems.com.au"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-img
            :src="logo"
            width="100"
            height="50"
          />
        </a>
        <v-spacer class="hidden-sm-and-down" />
        <v-col
          cols="12"
          md="auto"
        >
          <div class="my-footer-style">
            DBSystems Pty Ltd | ABN 96 626 727 615.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  import { cdnUrl } from 'src/app-globals';

  export default {
    name: 'DashboardCoreFooter',
    data: () => ({
      logo: `${cdnUrl}/website/DBSLogoDrawer.png`,
    }),
  };
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
    height: 0.1px
  #footer-container
    padding: 0px
    background-color: #CFD8DC
</style>
<style scoped>
.my-footer-style {
  color: #37474F;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: 600;
}
</style>
